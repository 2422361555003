import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    usuarioDB: {
      data: {
        role: localStorage.getItem('TipoUsuario') || '',
        _id: localStorage.getItem('IdUsuario') || '',
        name: localStorage.getItem('NameUsuario') || ''
      }
    }
  },
  mutations: {
    obtenerUsuario(state, payload) {
      state.token = payload || '';
      if (payload === '') {
        state.usuarioDB = null;
        localStorage.removeItem('TipoUsuario');
        localStorage.removeItem('IdUsuario');
        localStorage.removeItem('NameUsuario');
      } else {
        state.usuarioDB = decode(payload);
        const role = state.usuarioDB.data.role;
        localStorage.setItem('TipoUsuario', role); // Actualiza TipoUsuario en el almacenamiento local
        localStorage.setItem('IdUsuario', state.usuarioDB.data._id);
        localStorage.setItem('NameUsuario', state.usuarioDB.data.name);
        console.log('TipoUsuario actualizado en Vuex:', role); // Agrega un console.log para depurar
        if (role == 'administrator') {
          router.push({ name: 'administrador' });
        } else if (role == 'user') {
          router.push({ name: 'homeuser' });
        } else if (role == 'profesional') {
          router.push({ name: 'estaciones' });
        }
      }
    }
  },  
  actions: {
    guardarUsuario({ commit }, payload) {
      localStorage.setItem('token', payload);
      commit('obtenerUsuario', payload);
    },
    cerrarSesion({ commit }) {
      commit('obtenerUsuario', '');
      localStorage.removeItem('token');
      localStorage.removeItem('TipoUsuario');
      localStorage.removeItem('IdUsuario');
      localStorage.removeItem('NameUsuario');
      router.push({ name: 'login' });
    }
  },
  modules: {},
  getters: {
    UsuarioActivo: state => !!state.token,
    TipoUsuario: state => {
      if (state.usuarioDB) {
        return state.usuarioDB.data.role; 
      } else {
        return ''; 
      }
    },
    IdUsuario: state => {
      if (state.usuarioDB) {
        return state.usuarioDB.data._id;
      } else {
        return '';
      }
    },
    NameUsuario: state => {
      if (state.usuarioDB) {
        return state.usuarioDB.data.name; 
      } else {
        return '';
      }
    }
  }
});
