export default [
  {
    path: "/estaciones",
    name: "estaciones",
    component: () => import("../../views/Estaciones.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/administrador",
    name: "administrador",
    component: () => import("../../views/Administrador.vue"),
    meta: { requireAuth: true },
  },
  {
    path: "/userestacion",
    name: "userestacion",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/user-opciones/userEstacion"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/homeuser",
    name: "homeuser",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/user-opciones/homeuser"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/admin-user",
    name: "admin-user",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/options-admin/Usuarios.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/formuser",
    name: "formuser",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/user-opciones/formularioUser.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/report",
    name: "report",
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/station/reporte.vue"),
    meta: { requireAuth: true },
  },
];
