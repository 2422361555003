export default [
  {
    path: "/viewNutricion/:tipo" /*ruta antigua, se mantuvo para no romper lo de antes */,
    name: "viewNutricion",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/viewNutricion.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/viewNutricionCerezo/:tipo" /*ruta antigua, se mantuvo para no romper lo de antes */,
    name: "viewNutricionCerezo",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/viewNutricionCerezo.vue"
      ),
    meta: { requireAuth: true },
  },
  // Esto es todo lo nuevo, la ruta de abajo es la nueva para ver las tablas de fruta, esta debería ser la estructura estándar
  {
    path: "/viewNutricionalFruta/:tipo",
    name: "viewNutricionalFruta",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/fruitOptions/nutricionalFruta/viewNutricionalFruta.vue"
      ),
      meta: { requireAuth: true },
  },
  {
    path: "/viewNutricionFoliar",
    name: "viewNutricionFoliar",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/viewNutricionFoliar.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/viewFoliar/:tipo",
    name: "viewFoliar",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/fruitOptions/foliar/viewFoliar.vue"
      ),
      meta: { requireAuth: true },
  },
  {
    path: "/viewSuelo/:tipo",
    name: "viewSuelo",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/fruitOptions/suelo/viewSuelo.vue"
      ),
      meta: { requireAuth: true },
  },
  {
    path: "/viewParamsProductivos/:tipo",
    name: "viewParamsProductivos",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/fruitOptions/paramsProductivos/viewParamsProductivos.vue"
      ),
      meta: { requireAuth: true },
  },
  {
    path: "/viewParamsProductivosSec/:tipo",
    name: "viewParamsProductivosSec",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/fruitOptions/paramsProductivosSec/viewParamsProductivosSec.vue"
      ),
      meta: { requireAuth: true },
  },
  {
    path: "/selectNutri",
    name: "selectNutri",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/selectNutri.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/fruitOptions/:tipo",
    name: "fruitOptions",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/fruitOptions/options.vue"
      ),
    meta: { requireAuth: true },
  },
];
