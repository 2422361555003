<template>
  <div>
    <b-navbar toggleable="lg" class="navbar navbar-dark" style="background-color: #7cac32;">
      <b-navbar-brand :style="`${UsuarioActivo ? '' : 'display: none'}`">
        <b-button v-b-toggle.sidebar-menu-top style="background-color: #7cac32;">
          <b-icon icon="list"></b-icon>
        </b-button>
        <b-col>
          <SidebarComponent />
        </b-col>
      </b-navbar-brand>
      <b-navbar-brand>
        <router-link to="/" title="Plataforma IKAROS" style="user-drag: none;">
          <img :src="`${URL_BASE}/images/LOG_IKAROS.png`" class="img-fluid"
            style="max-height: 50px; max-width: 150px; user-drag: none;" />
        </router-link>
      </b-navbar-brand>
      <b-navbar-brand style="margin: auto;">
        <h2 class="h5 m-0" style="user-select: none; font-size: 1.45rem !important;"><strong>PLATAFORMA IKAROS</strong>
        </h2>
      </b-navbar-brand>
      <b-navbar-brand>
        <a title="Centro de Pomáceas" href="https://pomaceas.utalca.cl/" style="user-drag: none;">
          <img :src="`${URL_BASE}/images/05_CP_BLANCO.png`" class="img-fluid"
            style="max-height: 50px; max-width: 150px; user-drag: none;" />
        </a>
      </b-navbar-brand>
      <b-navbar-brand>
        <a title="Universidad de Talca" href="https://www.utalca.cl/" style="user-drag: none;">
          <img :src="`${URL_BASE}/images/06_UTALCA_BLANCO.png`" class="img-fluid "
            style="max-height: 50px; max-width: 150px; user-drag: none;" />
        </a>
      </b-navbar-brand>
    </b-navbar>
    <!-- <b-navbar toggleable="sm" class="navbar navbar-dark" style="background-color: #7cac32;" v-if="pantalla_ < 1024">
    <b-navbar-nav>
      <b-navbar-brand><b-button size="sm" v-b-toggle.sidebar-menu style="background-color: #7cac32;" ><b-icon icon="list"></b-icon></b-button></b-navbar-brand> 
      <b-navbar-brand><a title="Plataforma IKAROS" href="https://plataformaikaros.cl/"><img :src = "`${URL_BASE}/images/LOG_IKAROS.png`" width="30%" height="20%" /></a></b-navbar-brand>
      <b-navbar-brand><strong>PLATAFORMA IKAROS</strong></b-navbar-brand>
    </b-navbar-nav>
   </b-navbar> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import SidebarComponent from './SidebarComponent'

export default {
  name: 'NavBarComponent',
  data() {
    return {
      URL_BASE: process.env.VUE_APP_BASE_URL
    };
  },
  components: {
    SidebarComponent,
  },
  computed: {
    ...mapGetters(['UsuarioActivo', 'TipoUsuario'])
  },
  created() {
    this.tamanio();
  },
  methods: {
    ...mapActions(['cerrarSesion']),
    tamanio() {
      this.pantalla_ = screen.width;
    }
  }
}
</script>
<style>
.margen-imgcarr {
  margin-left: 16%;
}

.margen-textocarr {
  margin-left: 18%;
}

.no-draggable {
  user-drag: none;
}
</style>