import Vue from "vue";
import VueRouter from "vue-router";

// Rutas de cada tipo de usuario
import publicRoutes from "./public/publicRoute.js"; // Rutas que no requieren autenticación
import passwordRoutes from "./password/passwordRoute.js"; // Rutas para recuperar contraseña
import userRoutes from "./user/userRoute.js"; // Rutas generales de los usuarios
import deprecatedRoutes from "./deprecated/deprecatedRoute.js"; // Rutas que ya no se usan aparentemente o están sueltas.
import crearEditarRoutes from "./nutricion/crearEditar/crearEditarRoute.js"; // Rutas para crear y editar
import vistasNutricion from "./nutricion/vistas/vistaRoute.js"; // Rutas para ver las tablas de fruta
import stationRoutes from "./station/stationRoute.js"; // Rutas para las estaciones
import reporteRoutes from "./reportes/reporteRoute.js"; // Rutas para los reportes
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    ...publicRoutes,
    ...passwordRoutes,
    ...userRoutes,
    ...deprecatedRoutes,
    ...crearEditarRoutes,
    ...vistasNutricion,
    ...stationRoutes,
    ...reporteRoutes,
    {
      path: "*" /* En caso de que falte alguna ruta o se busque una no existente, esto redirige a un 404*/,
      name: "Not Found",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/404.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const rutaProtegida = to.matched.some((record) => record.meta.requireAuth);
  const token = localStorage.getItem("token");
  const tipoUsuario = localStorage.getItem("TipoUsuario");

  if (rutaProtegida && !token) {
    next({ name: "login" });
  } else if (token && to.name === "login") {
    if (tipoUsuario == "administrator") {
      next({ name: "administrador" });
    } else if (tipoUsuario == "user") {
      next({ name: "homeuser" });
    } else if (tipoUsuario == "profesional") {
      next({ name: "selectNutri" });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
