export default [
  {
    path: "/reportesadmin/crearEditar/:id?",
    name: "crearEditarReportes",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/options-admin/reports/reportecrearEditar.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/reportesadmin",
    name: "reportesadmin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/options-admin/ReportesAdmin.vue"
      ),
    meta: { requireAuth: true },
  },
];
