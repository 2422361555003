import axios from "axios";
export default [
  {
    path: "/forgotPassword",
    name: "forgotPasswordRoute",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/forgotPassword/forgotPassword.vue"
      ),
  },
  {
    path: "/recovery/:token",
    name: "recoveryRoute",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/recoveryPassword/recoveryPassword.vue"
      ),
    beforeEnter: (to, from, next) => {
      const token = to.params.token;

      axios
        .post("/verifyRecoveryToken", { token: token })
        .then((res) => {
          console.log(res);
          next();
        })
        .catch((e) => {
          console.log(e);
          next({ name: "Not Found" });
        });
    },
  },
];
