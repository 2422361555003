export default [
  {
    path: "/nutricion_user",
    name: "nutricion_user",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/user-opciones/nutricion_user"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/nutricionhistorica/:id",
    name: "nutricionhistorica",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/user-opciones/nutricionhistorica"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/select_nutri_user",
    name: "select_nutri_user",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/user-opciones/select_nutri_user"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/nutricion_usuario/:id",
    name: "nutricion_usuario",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/user-opciones/nutricion_user"
      ),
    meta: { requireAuth: true },
  },
];
