export default [
  {
    path: "/estacionesadmin",
    name: "estacionesadmin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/options-admin/AdminEstaciones.vue"
      ),
    meta: { requireAuth: true },
  },

  {
    path: "/station-view/:id",
    name: "station-view",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/station/stationView.vue"
      ),
    meta: { requireAuth: true },
  },
];
