export default [
  {
    path: "/viewNutricionPomaceas/crearEditar/:id?",
    name: "crearEditarNutricionPomaceas",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/fruitOptions/nutricionalFruta/crearEditarPomaceas/crearEditar.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/viewNutricionCerezo/crearEditar/:id?",
    name: "crearEditarNutricionCerezos",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/fruitOptions/nutricionalFruta/crearEditarCerezos/crearEditar.vue"
      ),
    meta: { requireAuth: true },
  },
  /* Las dos rutas de arribas tienen ese formato puesto que son reutilizando lo antiguo. De aquí hacia abajo es todo
  nuevo, se debe seguir este formato idealmente */
  {
    path: "/viewNutricionalFruta/crearEditar/:tipo/:id?",
    name: "crearEditarNutricion",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/fruitOptions/nutricionalFruta/crearEditar.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/viewFoliar/crearEditar/:tipo/:id?",
    name: "crearEditarFoliar",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/fruitOptions/foliar/crearEditar.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/viewSuelo/crearEditar/:tipo/:id?",
    name: "crearEditarSuelo",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/fruitOptions/suelo/crearEditar.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/viewParamsProductivos/crearEditar/:tipo/:id?",
    name: "crearEditarParamsProductivos",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/fruitOptions/paramsProductivos/crearEditar.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/viewParamsProductivosSec/crearEditar/:tipo/:id?",
    name: "crearEditarParamsSec",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/nutri/fruitOptions/paramsProductivosSec/tablas/crearEditar.vue"
      ),
    meta: { requireAuth: true },
  },
];
