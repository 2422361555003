<template>
  <div>
    <b-sidebar id="sidebar-menu-top" aria-labelledby="sidebar-menu-title" no-header shadow text-variant="light"
      bg-variant="darkgreen" backdrop>
      <template #default="{ hide }">
        <div class="p-3">
          <div class="d-flex align-items-center flex-column">
            <b-avatar v-if="TipoUsuario == 'user'" variant=dark class="mb-3 mt-3" text="U" size="5rem"></b-avatar>
            <b-avatar v-if="TipoUsuario == 'administrator'" variant=dark class="mb-3 mt-3" text="A"
              size="5rem"></b-avatar>
            <b-avatar v-if="TipoUsuario == 'profesional'" variant=dark class="mb-3 mt-3" text="A" size="5rem"></b-avatar>
            <h5 id="sidebar-no-header-title">{{ NameUsuario }}</h5>
            <h4 id="sidebar-no-header-title">Plataforma IKAROS</h4>
            <div class="divider-menu"></div>
          </div>
          <span class="break"></span>
          <nav class="mb-3">
            <b-nav vertical align="center" fill v-if="TipoUsuario == 'administrator'">
              <b-nav-item v-bind:to="'/administrador'" @click="hide"><font-awesome-icon icon="users-cog" />
                Administrador</b-nav-item>
              <b-nav-item v-bind:to="'/estaciones'" @click="hide"><font-awesome-icon icon="cloud-sun-rain" />
                Estaciones</b-nav-item>
              <b-nav-item v-bind:to="'/selectNutri'" @click="hide"><font-awesome-icon icon="seedling" />
                Nutricional</b-nav-item>
            </b-nav>
            <b-nav vertical align="center" fill v-if="TipoUsuario == 'user'">
              <b-nav-item v-bind:to="'/homeuser'" @click="hide"><font-awesome-icon icon="home" /> Inicio</b-nav-item>
              <b-nav-item v-bind:to="'/userestacion'" @click="hide"><font-awesome-icon icon="cloud-sun-rain" />
                Estaciones</b-nav-item>
              <b-nav-item v-bind:to="'/select_nutri_user'" @click="hide"><font-awesome-icon icon="seedling" />
                Nutricional</b-nav-item>
            </b-nav>
            <b-nav vertical align="center" fill v-if="TipoUsuario == 'profesional'">
              <b-nav-item v-bind:to="'/estaciones'" @click="hide"><font-awesome-icon icon="cloud-sun-rain" />
                Estaciones</b-nav-item>
              <b-nav-item v-bind:to="'/selectNutri'" @click="hide"><font-awesome-icon icon="seedling" />
                Nutricional</b-nav-item>
            </b-nav>
          </nav>
          <div class="divider-menu"></div>
          <b-nav-item @click="cerrarSesion()"><font-awesome-icon icon="window-close" /> Cerrar Sesión</b-nav-item>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
export default {
  data() {
    return {
      UsuarioRol: ''
    }
  },
  computed: {
    ...mapGetters(['TipoUsuario', 'NameUsuario', 'IdUsuario'])
  },
  methods: {
    ...mapActions(['cerrarSesion'])
  },
}
</script>


<style>
.nav-item>.nav-link {
  color: whitesmoke;
  padding: 0.5rem;
  font-size: 1.25rem;
}

.nav-item>.nav-link:hover {
  color: #eca549
}

.li {
  color: #eca549
}

.bg-darkgreen {
  background-color: #3c9812 !important
}

.divider-menu {
  border: 1px solid #ffffff;
  width: 100%;
}
</style>
