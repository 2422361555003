export default [
  {
    path: "/",
    name: "login",
    component: () => import("../../views/Login.vue"),
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: () => import("../../views/forgotPassword/forgotPassword.vue"),
  },
  {
    path: "/publico",
    name: "publico",
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/publico.vue"),
  },
  // Otras rutas públicas...
];
